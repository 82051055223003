import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';

import ServicesDetails1 from "../assets/images/services-details-image/services-details1.png";
import ServicesDetails2 from "../assets/images/services-details-image/services-details2.png";

const Design = () => (
    <Layout>
        <SEO title="UI/UX Design" /> 

        <Navbar />

        <div className="services-details-area ptb-80">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 services-details">
                    <div className="features-details-desc">
                    <h3>User Experience Design</h3>
                    <p>Design is the most critical aspect of product innovation. Our team leverages a team of trained scientists, designers, and researchers to ensure that your solution is designed to increase productivity, reduce human error, and provide an optimal product experience.</p>

                    <ul className="features-details-list">
                        <li>Usability Testing</li>
                        <li>Wireframing & Prototyping</li>
                        <li>Eye-Motion Tracking</li>
                        <li> A/B Testing </li>
                        <li>Human Factors and Ergonomics</li>

                    </ul>
                </div>
                    </div>

                    <div className="col-lg-6 services-details-image">
                        <img 
                            src={ServicesDetails1} 
                            className="animate__animated animate__fadeInUp" 
                            alt="image" 
                        />
                    </div>
                </div>

                <div className="separate"></div>

                <div className="row align-items-center">
                    <div className="col-lg-6 services-details-image">
                        <img 
                            src={ServicesDetails2} 
                            className="animate__animated animate__fadeInUp" 
                            alt="image" 
                        />
                    </div>

                    <div className="col-lg-6 services-details">
                        <div className="services-details-desc">
                            <p>Faraya employs doctorate-level talent in the areas of Human Systems Engineering and Human Factors to facilitate a formal approach to UI/UX design. We apply psychological and physiological principles to engineering better products that are designed with the end user in mind.</p>

                            <div className="services-details-accordion">
                                <Accordion allowZeroExpanded preExpanded={['a']}>
                                    <AccordionItem uuid="a">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>
                                                What is UI/UX Design?
                                                </span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            Instead of focusing on simply the look and feel of a product, User Interface and User Experience Design leverage design to optimize the way that a user engages with your product.                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem uuid="b">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>
                                                What is Eye Motion Tracking?
                                                </span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            We are able to leverage eye tracking technology to better understand human behavior. The use of this technology is used to assess and advance the design and development of both physical and software products.                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem uuid="c">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>
                                                Can you Conduct a Study on Our Behalf?
                                                </span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            Yes, our UI/UX process entails discovery, design, evaluation, and reporting. Throughout this process, we can conduct studies around your topic of interest, and provide insights to improve the design of your product or service.                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </Layout>
)

export default Design;